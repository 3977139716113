import request from '../request'

// 查询新闻时间列表
export function newlist(params) {
  return request({
    url: `/image/news`,
    method: 'get',
    params,
  })
}


// 新增新闻时间
export function addnew(data) {
  return request({
    url: `/image/news`,
    method: 'post',
    data,
  })
}

// 修改新闻时间
export function putnew(data) {
  return request({
    url: `/image/news`,
    method: 'put',
    data,
  })
}

// 删除新闻时间
export function delnew(data) {
  return request({
    url: `/image/news?ids=`+data.ids,
    method: 'delete',
    data,
  })
}


// 新闻时间详情
export function newinfo(id) {
  return request({
    url: `/image/news/`+id,
    method: 'get',
  })
}

