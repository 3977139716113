<template>
  <div class="home">
    <div class="all">
      <my-no-data v-if="newList.length == 0" conentTop="50%"></my-no-data>
      <div v-for="item in newList" :key="item.id" class="list">
        <div class="single" @click="godetail(item.id)">
          <img :src="item.cover" alt="">
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="profile">{{ item.profile }}</div>
            <div class="dateTime">{{ item.dateTime }}</div>
          </div>
        </div>
      </div>

      <div class="page" v-if="newList.length>0">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { newlist } from '@/api/news/index'
import MyNoData from '@/components/noData/index.vue';
export default {
  name: '',
  components: {
      MyNoData
  },
  // 定义属性
  data() {
    return {
      total: 0,
      newList: [],
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    init() {
      newlist(this.searchform).then((res) => {
        this.newList = res.data.records;
        this.total = res.data.total
      });
    },
    handleCurrentChange(e) {
      this.init(this.type, e);
    },
    godetail(id) {
      this.$router.push({
        path: 'detail?id=' + id
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.init()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.home {
  background: #EDF4F8;
  box-sizing: border-box;
  padding-top: 25px;
  padding-bottom: 80px;
  overflow-y: auto;
}

.all {
  width: 73%;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);

  .list {
    border-bottom: 1px solid #ddd;

    .single {
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      cursor: pointer;

      img {
        width: 250px;
        height: 165px;
      }

      .content {
        flex: 1;
        margin-left: 20px;
        padding: 5px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        .title {
          font-weight: 600;
          font-size: 18px;
          color: #333333;
          overflow: hidden;
          -webkit-box-orient: vertical;
        }

        .profile {
          font-weight: 400;
          font-size: 15px;
          color: #333333;
          overflow: hidden;
          -webkit-box-orient: vertical;
          line-clamp: 4;
        }

        .dateTime {
          font-weight: 400;
          font-size: 15px;
          color: #333333;
        }
      }
    }
  }

  .page {
    padding: 20px;
    padding-left: 0;

    // background: #f8f8f8;
    ::v-deep .el-pagination {
      .btn-prev {
        background: #fff;
      }

      .btn-next {
        background: #fff;
      }

      .el-pager {
        .number {
          background: #fff;
          color: #2440b3;
        }

        .number.active {
          background: #2440b3;
          color: #fff;
        }
      }
    }
  }
}
</style>