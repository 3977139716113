<template>
  <div
    class="no-data"
    :style="{
      minHeight: conentMinHeight,
    }"
  >
    <div
      class="show-content"
      :style="{
        top: conentTop + 'px',
      }"
    >
      <div class="no-data-icon">
        <img src="../../assets/image/icon/nodata.png" alt="">
      </div>
      <div
        class="no-data-text"
        :style="{
          fontSize: width / 12 + 'px',
          color: color,
        }"
      >
        NO DATA
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: {
    width: {
      type: Number,
      default: 300,
    },
    conentMinHeight: {
      default: '100vh',
    },
    conentTop: {
      type: String,
      default: "40%",
    },
    color: {
      type: String,
      default: "#c2e2ff",
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  width: 100%;
  height: 100%;
  position: relative;
  .show-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    .no-data-icon {
      margin: 0 auto;
      width: 100px;
      height: 100px;
      margin-bottom: 50px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .no-data-text {
      text-align: center;
      // position: absolute;
      // margin-top: 50%;
      // margin-left: 50%;
      transform: translate(0, -25px);
    }
  }
}
</style>
