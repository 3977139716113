var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-data",style:({
    minHeight: _vm.conentMinHeight,
  })},[_c('div',{staticClass:"show-content",style:({
      top: _vm.conentTop + 'px',
    })},[_vm._m(0),_c('div',{staticClass:"no-data-text",style:({
        fontSize: _vm.width / 12 + 'px',
        color: _vm.color,
      })},[_vm._v(" NO DATA ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-data-icon"},[_c('img',{attrs:{"src":require("../../assets/image/icon/nodata.png"),"alt":""}})])}]

export { render, staticRenderFns }